import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {fileExtension} from '../lib/tools'

import './fileIcon.scss'

const FILE_EXTENSION_MAP = Map({
  '1pif': 'vlt',
  avi: 'vid',
  bmp: 'img',
  csv: 'csv',
  doc: 'doc',
  docx: 'doc',
  eps: 'img',
  flv: 'vid',
  gif: 'img',
  heic: 'img',
  html: 'web',
  iff: 'dat',
  jpeg: 'img',
  jpg: 'img',
  kdb: 'dat',
  kdbs: 'dat',
  key: 'ppt',
  m3u: 'aud',
  m4a: 'aud',
  mov: 'vid',
  mp3: 'mp3',
  mp4: 'vid',
  mpa: 'aud',
  mpg: 'vid',
  numbers: 'xls',
  pages: 'doc',
  pdf: 'pdf',
  png: 'img',
  ppt: 'ppt',
  pptx: 'ppt',
  ps: 'img',
  qba: 'qbk',
  qbb: 'qbk',
  qbm: 'qbk',
  qbw: 'qbk',
  qdf: 'qbk',
  rm: 'vid',
  rtf: 'doc',
  sdf: 'dat',
  svg: 'img',
  swf: 'vid',
  tif: 'img',
  txt: 'doc',
  wav: 'aud',
  wma: 'aud',
  xls: 'xls',
  xlsx: 'xls',
  xml: 'dat',
  zip: 'zip'
})

const FileIcon = ({fileName}) => (
  <div className='file-icon'>
    <div className='file-extension'>
      {FILE_EXTENSION_MAP.get(fileExtension(fileName), 'N/A')}
    </div>
  </div>
)

FileIcon.propTypes = {
  fileName: PropTypes.string
}

export default FileIcon
